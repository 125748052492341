import { type Component, Show, Switch, Match, For } from "solid-js";
import { useSessionContext, useSiteContext } from "~/utils/contexts";
import { A } from "@solidjs/router";
import type { Product } from "~/services/roma-api/products/types";
import { pathMapping, PT } from "~/utils/products";
import { Colour } from "~/components/product/product-page";
import fractionString from "~/utils/fraction";
import { ProductTypeIcon } from "~/components/product";
import { Tooltip } from "~/components/ui";
import { Icon } from "solid-heroicons";
import { arrowTopRightOnSquare } from "solid-heroicons/solid";

type ListConfigType =
  | "size"
  | "color"
  | "category"
  | "finish"
  | "shape"
  | "price"
  | "availableAs"
  | "colors";

type ListConfigProductDetail = {
  type: ListConfigType;
  label: string;
  enabled: boolean;
};

type ListProductProps = {
  listConfigOption: ListConfigProductDetail;
  product: Product;
  type: string;
  rootClass?: string;
};

// *  COMPS * //
const Size: Component<ListProductProps> = (props) => {
  return (
    <div class={`fraction space-x-2 ${props.rootClass ?? ""} `}>
      <span class="font-sans">{props.listConfigOption.label}:</span>
      <Show when={props.product.Width}>
        <span class="whitespace-nowrap">
          W{fractionString(props.product.Width)}
        </span>
      </Show>
      <Show when={props.product.Height}>
        <span class="whitespace-nowrap">
          H{fractionString(props.product.Height)}
        </span>
      </Show>
      <Show when={props.product.Rabbet && props.product.Rabbet > 0}>
        <span class="whitespace-nowrap">
          R{fractionString(props.product.Rabbet)}
        </span>
      </Show>
      <Show when={props.product.Depth}>
        <span class="whitespace-nowrap">
          D{fractionString(props.product.Depth)}
        </span>
      </Show>
      <Show when={props.product.Face}>
        <span class="whitespace-nowrap">
          F{fractionString(props.product.Face)}
        </span>
      </Show>
    </div>
  );
};

const ProductDetailGeneric: Component<ListProductProps> = (props) => {
  const productPropertyMapping: Record<string, keyof Product> = {
    color: "ColourDescription",
    category: "Category",
    finish: "Finish",
    shape: "Profile",
    price: "Price",
  };

  const property = productPropertyMapping[props.listConfigOption.type];

  return (
    <Show when={props.product[property]}>
      <div class={`${props.rootClass ?? ""}`}>
        {props.listConfigOption.label}: {props.product[property] as string}
      </div>
    </Show>
  );
};

const Price: Component<ListProductProps> = (props) => {
  const { isPartner } = useSessionContext();
  return (
    <Show when={isPartner() && props.product?.Price}>
      <div class={`${props.rootClass ?? ""}`}>
        Price: ${props.product.Price.toFixed(2)}
      </div>
    </Show>
  );
};

const AvailableAs: Component<ListProductProps> = (props) => {
  return (
    <div class={`${props.rootClass ?? ""}`}>
      <p class="border-b border-gray-300 mb-2 pb-1">{props.listConfigOption.label}</p>
      <div class="flex flex-wrap gap-1">
        <For each={props.product.AvailableAs}>
          {(type) => (
            <ProductTypeIcon
              productType={type as PT}
              containerClass="border border-black"
              enableTooltip
            />
          )}
        </For>
      </div>
    </div>
  );
};

const AvailableColours: Component<ListProductProps> = (props) => {
  const { global } = useSiteContext();
  const colour = () => props.product?.Colour ?? "";
  return (
    <Show when={global()}>
      <div class={`${props.rootClass ?? ""}`}>
        <p class="border-b border-gray-300 mb-2 pb-1">{props.listConfigOption.label}</p>
        <div class="flex gap-1 flex-wrap">
          <Show
            when={!!global().colours[colour()]}
            // fallback={() => {
            // ? report to sentry so we have some log?
            //   console.log("COLOUR MISSING: ", colour(), props.product.SKU);
            //   return "";
            // }}
          >
            <Tooltip
              content={`${props.product.ColourDescription} (Shown)`}
              placement="bottom"
            >
              <Colour
                class="cursor-default"
                colour={global().colours[colour()][0]}
                metallic={global().colours[colour()][1]}
                current
              />
            </Tooltip>
          </Show>
          <For each={props.product.AvailableList}>
            {(item) => (
              <Show when={!!global().colours[item.Colour]}>
                <Tooltip
                  placement="bottom"
                  content={
                    <div class="flex justify-center item-center gap-1">
                      <span>{item.ColourDescription}</span>
                      <Icon path={arrowTopRightOnSquare} class="w-4 h-4" />
                    </div>
                  }
                >
                  <A
                    href={`/product/${item.SKU}${
                      pathMapping[props.type ?? PT.MOULDING]
                    }`}
                  >
                    <Colour
                      colour={global().colours[item.Colour][0]}
                      metallic={global().colours[item.Colour][1]}
                      // size="md"
                    />
                  </A>
                </Tooltip>
              </Show>
            )}
          </For>
        </div>
      </div>
    </Show>
  );
};

export const ListProductDetail: Component<ListProductProps> = (props) => {
  return (
    <Switch fallback={<ProductDetailGeneric {...props} />}>
      <Match when={props.listConfigOption.type === "price"}>
        <Price {...props} />
      </Match>
      <Match when={props.listConfigOption.type === "size"}>
        <Size {...props} />
      </Match>
      <Match when={props.listConfigOption.type === "availableAs"}>
        <AvailableAs {...props} />
      </Match>
      <Match when={props.listConfigOption.type === "colors"}>
        <AvailableColours {...props} />
      </Match>
    </Switch>
  );
};
