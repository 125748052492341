import { Show } from "solid-js";
import type { Accessor, Component, Setter } from "solid-js";
import { Slider } from "@kobalte/core/slider";
import { Icon } from "solid-heroicons";
import { squares_2x2, listBullet, cog_6Tooth, xMark } from "solid-heroicons/outline";
import { Motion, Presence } from "solid-motionone";
import { useShopContext } from "~/utils/contexts";

type GridSizeSliderProps = {
  displayMode: Accessor<"grid" | "list" | undefined>;
  setDisplayMode: Setter<"grid" | "list" | undefined>;
  showConfig: Accessor<boolean>;
  setShowConfig: Setter<boolean>;
  value: Accessor<number[]>;
  setValue: Setter<number[]>;
  minValue: number;
  maxValue: number;
};

export const GridSizeSlider: Component<GridSizeSliderProps> = (props) => {
  const { setShopConfig } = useShopContext();
  const ColumnSlider: Component = () => {
    return (
      <div class="flex items-center">
        <p class="text-sm px-1 mr-2">{props.value()}</p>
        <Slider
          class="relative flex flex-col items-center select-none touch-none w-16"
          minValue={props.minValue}
          maxValue={props.maxValue}
          value={props.value()}
          onChange={(val) => {
            props.setValue(val);
            setShopConfig("gridConfig", "prodsPerRow", val[0]);
          }}
        >
          {/* <div class="SliderLabel">
          <Slider.Label>Cols</Slider.Label>
          </div> */}
          <Slider.Track class="SliderTrack">
            <Slider.Fill class="SliderRange" />
            <Slider.Thumb class="SliderThumb">
              <Slider.Input />
            </Slider.Thumb>
          </Slider.Track>
        </Slider>
      </div>
    );
  };

  return (
    <div class=" flex items-center gap-4">
      <div class="flex items-center gap-1 border border-gray-300 rounded-full bg-white py-1 px-2 overflow-hidden">
        <button
          onClick={() => {
            props.setDisplayMode("list");
            setShopConfig("productDisplayPreference", "list");
          }}
          class={`${
            props.displayMode() === "list"
              ? "bg-roma-blue text-white"
              : ""
          } w-7 h-7 ml-1 rounded-md  flex justify-center items-center transition-colors duration-500`}
        >
          <Icon path={listBullet} class="w-6 h-6" />
        </button>
        <button
          onClick={() => {
            props.setDisplayMode("grid");
            setShopConfig("productDisplayPreference", "grid");
          }}
          class={`${
            props.displayMode() === "grid"
              ? "bg-roma-blue text-white"
              : ""
          } w-7 h-7  rounded-md  flex justify-center items-center transition-colors duration-500`}
          classList={{
            "bg-roma-blue text-white": props.displayMode() === "grid",
          }}
        >
          <Icon path={squares_2x2} class="w-6 h-6" />
        </button>
        <div class="w-24">
          <Presence exitBeforeEnter initial={false}>
            <Show when={props.displayMode() === "grid"}>
              <Motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20, transition: { duration: 0.3 } }}
              >
                <ColumnSlider />
              </Motion.div>
            </Show>
            <Show when={props.displayMode() === "list"}>
              <Motion.button
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20, transition: { duration: 0.3 } }}
                class="flex items-center justify-center text-sm border w-full border-gray-300 p-0.5 rounded-full text-roma-dark-grey bg-roma-grey"
                onClick={() => props.setShowConfig((prev) => !prev)}
              >
                <p>Configure</p>
                <Icon path={props.showConfig() ? xMark: cog_6Tooth} class="ml-1 w-4 h-4" />
              </Motion.button>
            </Show>
          </Presence>
        </div>
      </div>
    </div>
  );
};
