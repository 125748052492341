import { type Component, Show, For, createMemo, createSignal } from "solid-js";
import { useSearchParams } from "@solidjs/router";
import { useSiteContext } from "~/utils/contexts";
import { Img } from "../image";
import { Colour } from "../product/product-page";
import { Collapsible } from "../ui";
import { Icon } from "solid-heroicons";
import { check, xMark } from "solid-heroicons/outline";
import { sortByKey } from "~/utils/helpers";
import { type ShopSearchParams } from "~/utils/types";
import { EventType } from "@solid-primitives/analytics";

const PanelItem: Component<{
  key: keyof ShopSearchParams;
  image?: string;
  colour?: string;
  label: string;
  quantity?: number;
  metallic?: boolean;
}> = (props) => {
  const [params, setParams] = useSearchParams<ShopSearchParams>();
  const { track } = useSiteContext();
  const active = createMemo(
    () => params[props.key] && params[props.key]!.includes(props.label)
  );
  return (
    <button
      aria-label={`Filter by ${props.key}`}
      class="flex items-center text-left gap-2 text-sm hover:text-gray-500"
      onClick={() => {
        let current = new Set();
        if (params[props.key]) {
          if (params[props.key]!.includes("~")) {
            current = new Set(params[props.key]!.split("~"));
          } else {
            current.add(params[props.key]);
          }
        }
        if (current.has(props.label)) {
          current.delete(props.label);
        } else {
          track(EventType.Event, {
            category: "catalogue",
            action: "filtered",
            label: props.key.toLowerCase(),
            value: props.label,
          });
          current.add(props.label);
        }
        setParams(
          { [props.key]: Array.from(current).join("~") },
          { replace: true }
        );
      }}
    >
      <div
        class="w-5 aspect-square border rounded-sm cursor-pointer transition-colors flex justify-center items-center"
        classList={{
          "bg-roma-blue border-roma-blue": !!active(),
        }}
      >
        <Icon path={check} class="text-white w-4" stroke-width={3} />
      </div>
      <Show when={props.image && !props.colour}>
        <div class="w-6 aspect-square flex items-center">
          <Img
            src={props.image as string}
            alt="Image or colour representation"
            width={24}
            height={24}
            class="w-full"
          />
        </div>
      </Show>
      <Show when={props.colour}>
        <Colour colour={props.colour} metallic={props.metallic} />
      </Show>
      <Show when={!props.image && !props.colour && props.image == undefined}>
        <div class="w-6 border-t border-roma-dark-grey" />
      </Show>
      <div>{props.label}</div>
      <p>({props.quantity})</p>
    </button>
  );
};

export const FilterPanel: Component<{
  param: string;
  name: keyof ShopSearchParams;
  label: string;
  fadeBottom?: boolean;
  options?: Array<{
    image?: string;
    colour?: string;
    label: string;
    quantity?: number;
  }>;
}> = (props) => {
  const [params, setParams] = useSearchParams();
  const list = createMemo(() => {
    if (props.options) {
      const items = props.options.sort(sortByKey("label"));
      return items;
    }
    return [];
  });
  return (
    <Collapsible
      name={props.name}
      trigger={
        <div class="flex items-center gap-2 w-full">
          <span>{props.label}</span>
          <Show when={props.param && params[props.param] !== undefined}>
            <div class="bg-roma-blue text-white w-4  aspect-square rounded-full text-[9px] flex justify-center items-center">
              <span class="leading-none">
                {(params[props.param] as string).split("~").length}
              </span>
            </div>
          </Show>
        </div>
      }
      defaultOpen={false}
    >
      <div class="my-4 relative flex flex-col gap-2 max-h-72 overflow-y-auto">
        <For each={list()}>
          {(item) => (
            <PanelItem
              key={props.name.toLowerCase() as keyof ShopSearchParams}
              {...item}
            />
          )}
        </For>
      </div>
      <div class="flex justify-end items-center">
        <Show when={props.param && params[props.param] !== undefined}>
          <button
            class="text-sm text-roma-blue text-right z-20 flex items-center "
            onClick={() => {
              setParams({ [props.param]: undefined });
            }}
          >
            <span>Clear</span> <Icon path={xMark}  class="w-4 h-4 ml-1"/>
          </button>
        </Show>
      </div>
    </Collapsible>
  );
};
