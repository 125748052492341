import {
  type Component,
  createSignal,
  For,
  createEffect,
  Show,
  on,
  onMount,
  onCleanup,
} from "solid-js";
import { Dynamic } from "solid-js/web";
import { A } from "@solidjs/router";
import { useSessionContext, useShopContext } from "~/utils/contexts";
import type { Product } from "~/services/roma-api/products/types";
import { ProductImage } from "../../product";
import { Tooltip } from "~/components/ui";
import { Img } from "../../image";
import { profileIcons } from "~/utils/icons";
import { Icon } from "solid-heroicons";
import { arrowTopRightOnSquare, shoppingCart } from "solid-heroicons/outline";
import { PT } from "~/utils/products";
import { ListProductDetail } from "./ListProductDetail";
import { ProductImageModal } from "../../product";
import { clientOnly } from "@solidjs/start";
import { productImages } from "~/components/product/product-page/utils";

const ClientFavouriteHeartButton = clientOnly(() =>
  import("~/components/product/FavouriteHeartButton").then((mod) => ({
    default: mod.FavouriteHeartButton,
  }))
);

type ProductListItemProps = {
  class?: string;
  product: Product;
  type: any;
};

export const ProductListItem: Component<ProductListItemProps> = (props) => {
  const { shopConfig } = useShopContext();
  const { isPartner } = useSessionContext();
  const [isVisible, setIsVisible] = createSignal(false);
  const [activeImage, setActiveImage] = createSignal(
    shopConfig.listConfig.mainImages[props.type ?? PT.MOULDING]
  );
  let containerRef: HTMLDivElement | undefined;

  onMount(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        rootMargin: "100px",
        threshold: 0.1,
      }
    );

    if (containerRef) {
      observer.observe(containerRef);
    }

    onCleanup(() => observer.disconnect());
  });

  createEffect(
    on(
      () => shopConfig.listConfig.mainImages[props.type],
      () => setActiveImage(shopConfig.listConfig.mainImages[props.type] ?? 0)
    )
  );

  const pathMapping = (type: string) => {
    switch (type) {
      case PT.MOULDING:
        return "";
      case PT.PHOTOFRAME:
        return "/photo-frame";
      case PT.GALLERYFRAME:
        return "/gallery-frame";
      case PT.MIRROR:
        return "/mirror";
      case PT.CONTRACT:
        return "?type=contract";
      default:
        return "";
    }
  };

  const ActionButton: Component<{
    icon: IconPath;
    onClick?: () => void;
    href?: string;
    tooltip?: string;
  }> = (props) => {
    return (
      <Tooltip content={props.tooltip}>
        <Dynamic
          component={props.onClick ? "button" : A}
          onClick={props.onClick}
          href={props.href}
          class="bg-white rounded-md border border-gray-300 flex items-center justify-center w-8  aspect-square active:translate-y-0.5"
        >
          <Icon path={props.icon} class="w-5 h-5" />
        </Dynamic>
      </Tooltip>
    );
  };

  const ListItemSkeleton: Component = () => {
    return (
      <div class="h-[246px]  w-full rounded-lg flex items-center p-2">
        <div class="flex gap-x-1 items-center w-full h-[220px]">
          <div class="w-[220px] shrink-0 aspect-square rounded-lg bg-gray-300 animate-pulse" />
          <div class="bg-gray-300 animate-pulse delay-300 h-full rounded-lg w-full ml-3"></div>
        </div>
      </div>
    );
  };

  return (
    <div
      ref={(ref) => (containerRef = ref)}
      class="col-span-full bg-roma-grey  p-5 flex flex-col md:flex-row gap-3  border border-transparent hover:border-gray-200"
    >
      <Show when={isVisible()} fallback={<ListItemSkeleton />}>
        <div class="flex flex-col md:flex-row gap-1">
          <A
            href={`/product/${props.product.SKU}${pathMapping(
              props.type ?? PT.MOULDING
            )}`}
            class="w-[220px] h-[220px] shrink-0 aspect-square p-2 rounded-l-lg group relative overflow-hidden"
          >
            <ProductImage
              src={productImages(props.product.SKU, props.type)[activeImage()]}
              width={220}
              aspectRatio={1}
              quality={85}
              loading="lazy"
              // class={`group-hover:scale-150`}
            />
            <div class="opacity-0 group-hover:opacity-100 absolute bottom-0 left-0 text-xs bg-white rounded-lg border border-grey-300 px-2 py-1">
              View Product
            </div>
          </A>
          <div class="flex flex-row md:flex-col md:justify-center gap-1 w-9 shrink-0">
            <For each={productImages(props.product.SKU, props.type)}>
              {(item, i) => (
                <button
                  class="aspect-square border w-9 shrink-0 "
                  classList={{
                    "border-gray-400": activeImage() === i(),
                  }}
                  onClick={() => setActiveImage(i())}
                >
                  <ProductImage
                    class="bg-white p-0.5"
                    src={item}
                    aspectRatio={1}
                    quality={85}
                    width={80}
                    loading="lazy"
                  />
                </button>
              )}
            </For>
          </div>
        </div>
        <div class="md:border-l md:border-gray-300 pl-3 flex flex-col w-full text-sm">
          <div class="font-bold text-lg flex flex-col md:flex-row  md:items-center justify-between  border-b border-gray-300 pb-2">
            <div class="flex items-center w-full">
              <Show
                when={
                  props.product.Profile && profileIcons[props.product.Profile]
                }
              >
                <Img
                  src={profileIcons[props.product.Profile] as string}
                  alt="Image or colour representation"
                  width={24}
                  height={24}
                  class="shrink-0 mr-2"
                />
              </Show>
              <span class="block">
                {props.product.SKU} | {props.product.Collection}
              </span>
              <Show when={props.product.IsNew}>
                <div class="ml-2 text-xs bg-roma-blue text-white rounded-full px-2 flex justify-center items-center">
                  New
                </div>
              </Show>
            </div>
            <div class=" flex items-center gap-1">
              <ActionButton
                icon={arrowTopRightOnSquare}
                tooltip="View Product"
                href={`/product/${props.product.SKU}${pathMapping(
                  props.type ?? PT.MOULDING
                )}`}
              />
              <Show when={isPartner()}>
                <ActionButton
                  icon={shoppingCart}
                  tooltip="Place Order"
                  href={`/order?sku=${props.product.SKU}${
                    [PT.PHOTOFRAME, PT.GALLERYFRAME].includes(props.type)
                      ? `&type=${props.type}`
                      : ""
                  }`}
                />
              </Show>
              <Tooltip content={"Expand images"}>
                <ProductImageModal product={props.product} type={props.type} />
              </Tooltip>
              <Show when={isPartner()}>
                <Tooltip content={"Add/Remove Favourite"}>
                  <div class="bg-white rounded-md border border-gray-300 flex items-center justify-center w-8  aspect-square active:translate-y-0.5">
                    <ClientFavouriteHeartButton
                      sku={props.product.SKU}
                      type={props.type}
                      color={"text-roma-dark-grey"}
                    />
                  </div>
                </Tooltip>
              </Show>
            </div>
          </div>
          <div class=" grid md:grid-cols-[1fr_1fr] gap-2 w-full mt-2">
            <For each={shopConfig.listConfig.options}>
              {(group) => (
                <div class="w-full flex flex-col child:py-1">
                  <For each={group}>
                    {(item) => (
                      <Show when={item.enabled}>
                        <ListProductDetail
                          listConfigOption={item}
                          product={props.product}
                          type={props.type}
                        />
                      </Show>
                    )}
                  </For>
                </div>
              )}
            </For>
          </div>
        </div>
      </Show>
    </div>
  );
};
